<template>
  <div id="paypal-payments">
    <!--navigation></navigation-->
    <section class="section section-stats center">
      <div class="row paypal-payments">
        <div class="col s12 m6 offset-m3">
          <div class="card-panel center">
            <div v-if="!paid">
              <!--h4 class="flow-text">to do Room Description</h4-->
              <!--h4 class="flow-text">{{ $t('duration') }} {{ payData.daysBetween.values.days }} {{ $t('days')}}</h4-->
              <h4 class="flow-text">{{ duration }}</h4>
              <!--h4 class="flow-text">{{  $t('total_price') }} {{ payData.totalPrice }} €</h4-->
              <h4 class="flow-text">{{ total }}</h4>
            </div>
            <div v-if="error">
              <!-- for further templating or functionality -->
            </div>
            <div ref="paypal_button"></div>
            <br>
            <a href="" @click="goToHome"
               class="btn tooltipped back-button btn-small orange lighten-2 waves-effect waves-light z-depth-0"
               data-tooltip="Back to main page!" data-position="right"
               style="width: 120px">
              {{ $t('cancel') }}
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
// import axios from 'axios'
// import { DateTime } from 'luxon'

import userMixin from '@/mixins/userMixin'
import profileMixin from '@/mixins/profileMixin'
import roomMixin from '@/mixins/roomMixin'
import homeMixin from '@/mixins/homeMixin'

export default {
  name: 'Paypal',
  components: {
    //
  },
  data() {
    return {
      paid: false,
      error: false,
      loaded: false,
      order: {},
      amount: null,
      remarks: null,
      payment_typ: 'paypal'
    }
  },

  mounted() {
    const script = document.createElement("script")
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AS4zLRFcYBJ_WPABBTLYB0GRW8sNSS-t1VrD9_2Ln5sIl_iJxSlBCSPxM64aMVORUPevOtkn-q2K-BDS&vault=true&disable-funding=credit,card";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },

  methods: {
    setLoaded: function () {
      this.loaded = true;
      window.paypal.Buttons({
        locale: 'de_DE',
        style: {
          size: 'small',
          label: 'checkout'
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: 'Room Designation Comes here ...',
                amount: {
                  currency_code: "USD",
                  value: this.payData.totalPrice
                }
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          this.paid = true;
          this.amount = order.purchase_units[0].amount.value
          this.remarks = order.purchase_units[0].description
        },
        onError: err => {
          console.log(err);
          this.error = true
          this.$router.push({name: 'home'})
          M.toast({html: this.$i18n.t('something_went_wrong'), classes: 'rounded orange-text'})
          // alert('Something went wrong !')
        }
      })
        .render(this.$refs.paypal_button);
    },
    async storeReservation(reservation) {
      await this.$store.dispatch('rooms/storeReservation', reservation).then((response) => {
        M.toast({html: this.$i18n.t('thank_for_payment'), classes: 'rounded orange-text'})
        // alert('Thank you for your payment')
        // store all data for invoice and email vuex store
        this.$store.dispatch('storeCoreData', {
          room: this.room,
          hirer: this.hirer,
          hirerProfile: this.hirerProfile,
          customer: this.user,
          customerProfile: this.profile,
          firstDate: this.firstDate,
          lastDate: this.lastDate,
          duration: this.duration,
          total: this.total
        })
        // PaymentMail component: create invoice in pdf to download and send mails to customer and hirer
        this.$router.push({name: 'payment-mail'})
      })
    }
  },

  computed: {
    isAuthenticated() {
      return (this.$store.getters['auth/authenticated'] && this.token())
    },
    payData() {
      return this.$store.getters['rooms/confirmationData']
    },
    token() {
      return this.$store.getters['auth/token']
    },
    redirectUrl() {
      window.location.href = this.$store.getters['rooms/approvedUrl']
    },
    duration() {
      let count = this.payData.daysBetween.values.days
      return this.$i18n.t('duration') + ' ' + count + ' ' + this.$i18n.tc('days', count)
    },
    total() {
      // console.log('User');
      // console.log(this.user.username);
      return this.$i18n.t('total_price') + ' ' + this.payData.totalPrice + ' ' + '€'
    },
    firstDate() {
      return this.$store.getters['rooms/prStart']
    },
    lastDate() {
      return this.$store.getters['rooms/prEnd']
    },
    hirer() {
      return this.$store.getters['auth/hirer']
    },
    hirerProfile() {
      return this.$store.getters['auth/hirerProfile']
    }
  },

  watch: {
    paid: function (val) {
      // console.log('PayPal PAID: ', val)
      if (val === true) {
        const reservation = {
          startdate: this.$store.getters['rooms/ocCheckin'],
          enddate: this.$store.getters['rooms/ocCheckout'],
          user_id: this.user.id,
          room_id: this.room.id,
          amount: this.amount,
          ispaid: 1,
          remarks: this.remarks,
          payment_type: 'paypal'
        }
        this.storeReservation(reservation)
      }
    }
  },
  mixins: [roomMixin, userMixin, profileMixin, homeMixin]
}
</script>

<style scoped>
.paypal-payments {
  margin-top: 30px;
}
</style>
